import * as React from "react";
import { ApolloClient } from "apollo-boost";
import {
  FBIntentInput,
  AskAboutTopics,
} from "../../../apollo-types/globalTypes";
import { useQueryCovidLiveIntents } from "../../../hooks/live-queries/use-query-live-intents/use-query-live-intents";
import { IntentDetailFrament_category } from "../../../apollo-types/IntentDetailFrament";
import { useCovidIntentCategoriesQuery } from "../../../hooks/static-queries/use-covid-intent-categories-query";
import { Topic } from "../../../types/topics";
import IntentForm from "./intent-form";
import { createIntentByTopic } from "./intent-admin-services";

interface CreateIntentCovidProps {
  client: ApolloClient<unknown>;
}

const CreateIntentCovid: React.FC<CreateIntentCovidProps> = ({ client }) => {
  const { liveIntents } = useQueryCovidLiveIntents();
  const categories: IntentDetailFrament_category[] =
    useCovidIntentCategoriesQuery() || [];

  const handleSubmit = (input: FBIntentInput) =>
    createIntentByTopic({ client, topic: AskAboutTopics.COVID19, input });

  return (
    <div>
      <IntentForm
        liveIntents={liveIntents || []}
        categories={categories}
        isUpdateIntent={false}
        onSubmit={handleSubmit}
        topic={Topic.COVID19}
      />
    </div>
  );
};

export default CreateIntentCovid;
