import * as React from "react";
import { useGetAuthToken } from "../../../hooks/use-get-auth-token";
import LoadingState from "../../../components/ui-library/loading-state/loading-state";
import { BasicLayout } from "../../../components/layout";
import CreateIntentCovid from "../../../components/internal/intent-editor/create-intent-covid";
import { createApolloClient } from "../../../core/create-apollo-network-layer";
import { API_URL } from "../../../constants/urls";
import SignInNudge from "../../../components/internal/sign-in-nudge/sign-in-nudge";

const CreateIntentPage: React.FC = () => {
  const { loading, userToken } = useGetAuthToken();

  if (loading) {
    return (
      <BasicLayout>
        <LoadingState />
      </BasicLayout>
    );
  }

  if (!userToken) {
    return (
      <BasicLayout>
        <SignInNudge />
      </BasicLayout>
    );
  }

  const askAboutApolloClient = createApolloClient({
    uri: API_URL,
    token: userToken,
  });

  return (
    <BasicLayout>
      <CreateIntentCovid client={askAboutApolloClient} />
    </BasicLayout>
  );
};

export default CreateIntentPage;
